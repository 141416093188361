import '@aws-amplify/ui-react/styles.css';
import {
    Button,
    Collection,
    Heading,
    View,
    withAuthenticator,
    Card,
    Divider,
    Text,
    Alert, Image, Loader, Flex, Icon
} from '@aws-amplify/ui-react';
import {FaCheck} from 'react-icons/fa6'
import {useRef, useEffect, useState} from "react";
import {listNotes} from "./graphql/queries";
import {createNote as createNoteMutation} from "./graphql/mutations";
import {API, Auth} from "aws-amplify";
import { StorageManager, StorageImage } from '@aws-amplify/ui-react-storage';

const App = ({ signOut }) => {
    const [notes, setNotes] = useState([]);
    const [userInfo, setUserInfo] = useState(null)
    const maxFileCountUpload = 100;

    const errRef = useRef({hasError: false});
    const ref = useRef(null);

    useEffect(()=> {
        const fetchUser = async () => {     // You can await here     const response = await MyAPI.getData(someId);     // ...   }   fetchData(); }, [someId]);
            setUserInfo(await Auth.currentUserInfo());
        }
        fetchUser();
    }, []) // <-- empty dependency array

    useEffect(() => {
        fetchNotes();
    }, []);

    const fetchNotes = async () => {
        const apiData = await API.graphql({ query: listNotes, variables: {limit: 1000000}})
        const notesFromAPI =apiData.data.listNotes.items;
        setNotes(notesFromAPI);
    }

    const createNote = async (key) => {
        const data = {
            name: key,
            description: "", // todo add description
            createdBy: userInfo.attributes.email,
        };
        await API.graphql({
            query: createNoteMutation,
            variables: { input: data },
        });
        fetchNotes();
    }

    /**
     const deleteNote = async ({ id, name }) => {
        const newNotes = notes.filter((note) => note.id !== id)
        setNotes(newNotes);
        await Storage.remove(name);
        await API.graphql({
            query: deleteNoteMutation,
            variables: { input: { id } },
        });
    }
     **/
    const processFile = async ({ file }) => {
        const fileExtension = file.name.split('.').pop();
        const description = file.name.replace('|', '===').split('.', file.name.split('.').length - 1).join(".");

        return file
            .arrayBuffer()
            .then((filebuffer) => window.crypto.subtle.digest('SHA-1', filebuffer))
            .then((hashBuffer) => {
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray
                    .map((a) => a.toString(16).padStart(2, '0'))
                    .join('');
                return { file, key: `${description}|${d.getTime()}|${hashHex}.${fileExtension}` };
            });
    };
    const d = new Date();
    const text = (
            <View as={"span"}>
                Teilt hier gerne eure Bilder, Videos, Tonspuren oder Texte als Erinnerungen (Drag-And-Drop).
                <br/>
                Wenn ihr viele Dateien auf einmal habt, ladet diese am besten in einem .zip Bundle (ohne Unterordner) hoch.
                <br/>
                Wir freuen uns auf eure Schnapschüsse!
                <br/>
                <br/>
            </View>
    )
    return (
        userInfo ?
        <View className="app">
            <Alert variation={"info"} isDismissible>
                Hochgeladene Medien sind von den anderen Gästen der Hochzeit einseh- und herunterladbar.
                <br/>
                Zum löschen bereits hochgeladener Dateien, bitte wendet euch an tc4k3@icloud.com.
            </Alert>
            <View className={"head"}>
                <Heading level={1}>Wir sammeln die schönsten Momente der Hochzeit</Heading>
                <Text marginTop={"2rem"}>Wir freuen uns wenn ihr Eure Impressionen (Handybilder, etc) mit uns teilt.</Text>
                <Divider marginTop={"2rem"} className={"signature"} label={"Hannah & Andi"} maxWidth="12rem"/>
            </View>
            <View className="view-main-image">
                <Card variation={"outlined"} maxWidth={"800px"}>
                    <Image src={"we.jpg"} alt={"Hochzeitsfoto mit Andi und Hannah."} maxWidth={"500px"}/>
                </Card>
                <Text marginTop={"2rem"} fontStyle={"italic"}>Weil das Zauberhafte ebenso wirklich ist wie das Wirkliche zauberhaft</Text>
            </View>
            <View className="view-gradient-finisher" />
            <View className="view-gradient-whiteout" />
            <View className="film" />
            <View margin="0 0 0 0" className={"view-storage-manager"}>
                <StorageManager
                    acceptedFileTypes={['image/*', 'video/*', 'audio/*', 'text/*', 'application/zip']}
                    accessLevel="public"
                    maxFileCount={maxFileCountUpload}
                    onUploadSuccess={({key}) => {
                        createNote(key);
                    }}
                    isResumable
                    showThumbnails={true}
                    processFile={processFile}
                    components={{
                        FilePicker({ onClick }) {
                            const cleanPick = () => { // todo sooo dirty, but I don't see another way... :)
                                const {hasError} = errRef.current
                                if (hasError) {
                                    ref.current.clearFiles();
                                    errRef.current={hasError: false}
                                }
                                onClick();
                            }
                            return (
                                <Button variation="primary" onClick={cleanPick} borderColor={"whitesmoke"} color={"white"} backgroundColor={"deepskyblue"}>
                                    Dateien hochladen
                                </Button>
                            );
                        },
                        FileList({ files, displayText, onCancelUpload, onDeleteUpload, hasMaxFilesError, maxFileCount}) {
                            if (hasMaxFilesError) {
                                errRef.current = {hasError: hasMaxFilesError};
                            }
                            return (
                                <Flex className={"upload-container"}>
                                    {hasMaxFilesError ? displayText.getMaxFilesErrorText(maxFileCount) : files.map(({file, key, progress, id, status, uploadTask}) => (
                                        <View marginBottom={"5rem"} key={key}>
                                            <Card
                                                className={"upload-item"}
                                                key={key}
                                                backgroundColor={"lightskyblue"}
                                                variation={"outlined"}
                                                borderWidth={"medium"}
                                                maxWidth={132}
                                            >
                                                <Image
                                                    borderRadius="small"
                                                    height="100%"
                                                    objectFit="cover"
                                                    src={URL.createObjectURL(file)}
                                                    alt={key}
                                                    minWidth={20}
                                                    maxWidth={100}
                                                    minHeight={20}
                                                    maxHeight={100}
                                                />
                                                <Loader
                                                    variation={"linear"}
                                                    size="small"
                                                    filledColor={"rgba(133,82,255,0.2)"}
                                                    percentage={progress}
                                                    isDeterminate
                                                    isPercentageTextHidden
                                                />
                                                {progress < 100 ? (
                                                        <Button
                                                            opacity="50"
                                                            borderRadius="xxl"
                                                            backgroundColor="background.primary"
                                                            variation="link"
                                                            size="small"
                                                            onClick={() => {
                                                                if (status === 'uploading') {
                                                                    onCancelUpload({id, uploadTask});
                                                                } else {
                                                                    onDeleteUpload({id});
                                                                }
                                                            }}
                                                        >
                                                            <Icon
                                                                fontSize="large"
                                                                color="black"
                                                                viewBox={{width: 512, height: 512}}
                                                                paths={[
                                                                    {
                                                                        d: 'M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z',
                                                                        strokeWidth: '32',
                                                                        fill: 'none',
                                                                        strokeMiterlimit: '10',
                                                                        stroke: 'currentColor',
                                                                    },
                                                                    {
                                                                        d: 'M320 320L192 192m0 128l128-128',
                                                                        strokeWidth: '32',
                                                                        fill: 'none',
                                                                        strokeLinecap: 'round',
                                                                        stroke: 'currentColor',
                                                                    },
                                                                ]}
                                                            />
                                                        </Button> )
                                                    :
                                                    <Icon
                                                        fontSize="large"
                                                        color="green"
                                                        viewBox={{width: 512, height: 512}}
                                                        as={FaCheck}
                                                    />
                                                }
                                            </Card>
                                        </View>
                                    ))}
                                </Flex>
                            )
                        },
                    }}
                    displayText={{
                        dropFilesText: text,
                        getFilesUploadedText(count) {
                            return "Deine Dateien wurden erfolgreich unseren Erinnerungen hinzugefügt.";
                        },
                        getRemainingFilesText(number) {
                            let text = null;
                            if (number<=maxFileCountUpload) text = (number === 1) ? // todo this suxxx, but I don't find a way to propagate the error here.
                                    <View as={"span"}>Eine verbleibende Datei wird synchronisiert... <Loader marginLeft={"1rem"}/> <br/> <br/></View> :
                                    <View as={"span"}>Erinnerungen werden synchronisiert... ({number} Dateien verbleibend)<Loader marginLeft={"1rem"}/> <br/> <br/></View>
                            return text;
                        },
                        getMaxFilesErrorText(count) {
                            return <Alert textAlign={"left"} heading={"Fehler beim Hochladen"} variation={"error"}>
                                Es können derzeit aus Performancegründen maximal {count} Dateien gleichzeitig hochgeladen werden.<br /> <br />
                                Wenn du mehr zu teilen hast, bitte lade deine Medien in mehreren Paketen nacheinander hoch.<br/>
                                Alternativ kannst du deine Dateien in ein oder mehrere .zip files (ohne Unterordner) packen.<br/>
                                Diese werden automatisch nach dem hochladen entpackt, so dass die Bilder ebenfalls in unseren Erinnerungen erscheinen.
                                <br/>
                                <br/>
                                Danke!<br/>
                                - Hannah & Andi -
                            </Alert>
                        }
                    }}
                    ref={ref}
                />
            </View>
            <View className="film" />
            <View>
                <View className={"bg-memories"}>
                    <Heading level={2}>Erinnerungen</Heading>
                    <Text marginTop={"1rem"}>Danke Euch allen für die zauberhafte Feier!</Text>
                </View>
                <View className={"bg-collection"}>
                    <Collection
                        paddingTop={"3rem"}
                        items={notes}
                        type="list"
                        direction="row"
                        gap="20px"
                        wrap="wrap"
                        justifyContent={"space-around"}
                        isPaginated={true}
                        itemsPerPage={10}
                    >
                        {(item, index) => (
                            <Card
                                key={index}
                                borderRadius="medium"
                                borderColor="#002266"
                                maxWidth="20rem"
                                variation="outlined"
                            >
                                <StorageImage
                                    accessLevel="public"
                                    imgKey={item.name}
                                    alt={"<empty>"}
                                    maxWidth={200}
                                    maxHeight={200}
                                />
                                <Divider padding="xs" />
                                <Heading padding="medium">{item.name.split('|')[0].split('.')[0]}</Heading>
                            </Card>
                        )}
                    </Collection>
                </View>
            </View>
            <View className={"bg-signout"}>
                <Button margin={"3rem 0"} onClick={signOut}>Sign Out</Button>
            </View>
        </View>
            : null
    );
};

export default withAuthenticator(App);

